import asteroidleft from "@/assets/images/invest/5.webp";

export default function Calendly() {
    return (
        <section id="calendly" className='relative flex flex-col items-center justify-center gap-y-8 lg:gap-y-[59px] mt-5 lg:mt-0'>
            <img
                src={asteroidleft}
                alt="asteroids"
                className="absolute -top-[600px] left-0 h-0 lg:h-[600px] z-[0]"
            />
            
        </section>
    )
}