import { slideProps } from "@/components/animations/utils";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import InvestorStars, { SmallStars } from "./investor-stars";
import asteroids from "@/assets/images/invest/4.webp";
import asteroidleft from "@/assets/images/invest/5.webp";
import { Button } from "@/components/ui/button";

export default function PitchDeck() {

    const ref = useRef(null);
    const iframeRef = useRef(null);
    const isInView = useInView(ref);
    const isInViewIframe = useInView(iframeRef);

    const [hasAnimated, setHasAnimated] = useState(false);

    useEffect(() => {
        if (isInView) setHasAnimated(true);
    }, [isInView])

    const scrollToTypeform = () => {
        const typeform = document.getElementById('typeform-container');
        if (typeform) {
            typeform.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <section id="pitch-deck" className='relative flex flex-col items-center justify-center lg:gap-y-[100px] mt-[70px] lg:mt-[179px]'>
            <img
                src={asteroids}
                alt="asteroids"
                className="absolute -top-[200px] right-0 h-0 lg:h-[940px] z-[0]"
            />
            <div ref={ref} className="flex flex-col w-full max-w-[883px] gap-y-8 mx-auto">
                <motion.div className='relative flex flex-col gap-y-[42px] col-span-1 w-full max-w-[883px] justify-center items-center' {...slideProps("up", hasAnimated)}>
                    <InvestorStars className="absolute -bottom-[36.3%] w-0 lg:w-full h-[180px] lg:h-[419px]" />
                    <SmallStars className="absolute -bottom-[25.3%] h-[180px] w-full lg:hidden" />
                    <div className='relative flex flex-col w-full items-center px-4 gap-y-9 lg:gap-y-[19px]'>

                        <div className="relative aspect-[9_/_5.6] rounded-xl bg-[#070E22] w-full p-2 lg:p-4">

                            <motion.iframe
                                src="https://docs.google.com/presentation/d/e/2PACX-1vTSOsIcpnUxgLAYD1fWN6bKP43_LocG6vEbcwauuai3k9z0sQ3w5CmG0cQ2nCLrqzEWIaaJmi7SlbA-/embed?start=false&loop=false&delayms=3000"
                                frameBorder="0"
                                width="900"
                                height="900"
                                allowFullScreen={true}
                                ref={iframeRef}
                                className='w-full h-full rounded-xl bg-black'
                                {...slideProps("up", isInViewIframe)}
                            />
                        </div>
                        <div>
                            <Button variant='invest-cta' className='!h-10 lg:!h-[58px]' onClick={() => window.open("https://calendly.com/ani-liz/ani-30-minute-mtg", "_blank")}>
                                Speak with our CEO
                            </Button>
                        </div>
                    </div>
                </motion.div>
                <div className="relative flex flex-col gap-y-5 w-full max-w-[700px] mx-auto mt-36 px-4">
                    <p className="text-investor-green text-[16px] leading-5 text-center lg:text-2xl lg:leading-7 lg:text-left font-nekstbold font-bold">
                        The biggest need in AI over the next decade is not compute power but data - dynamic, curated, structured data.
                    </p>
                    <p className="text-white text-lg leading-5 text-center lg:text-left font-figtree">
                        The biggest market opportunity in decentralized gaming is a top-flight publisher. <br className="block lg:hidden" /><br className="block lg:hidden" />
                        Over the past 3 years, Lizard Labs has relentlessly built the key elements to a successful publisher. <br/><br/>
                        Now, with the limits of open-access static internet data being reached by AI models, 
                        the demand for private and dynamic data is skyrocketing. Lizard Labs harnesses the Power of Play to meet that need. 
                    </p>
                </div>
            </div>
        </section>
    )
}