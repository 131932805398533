import logo from '@/assets/images/logo.png';
import { useSections } from '@/components/section-provider';
import { Icons } from '@/components/ui/icons';
import { AnimatePresence, motion } from 'framer-motion';
import greenRoom from '@/assets/images/invest/green-room.webp';

import { Button } from '@/components/ui/button';
import { X } from 'lucide-react';
import { ReactNode, useCallback, useRef } from 'react';
import WheelReact from 'wheel-react';
import { BrowserView, MobileView } from 'react-device-detect';
import { cn } from '@/lib/utils';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import Separator from './ui/separator';

interface FooterContentProps {
    setShowFooter?: (state: boolean) => void;
    openAccordion?: boolean;
}

const iconClass = 'bg-[#131313] w-10 h-10 border-[#2F2F2F] border rounded flex justify-center items-center hover:bg-[#131313]/90 hover:scale-[1.02]';

export const SocialIcons = ({ className, iconClassName }: { className?: string, iconClassName?: string }) => (
    <div className={cn('flex gap-[11px]', className)}>
        {/* <a href="https://www.linkedin.com/company/ethlizards/" target='_blank' className={cn(iconClass, iconClassName)}><Icons.linkedInFooter className='w-5 h-auto' /></a> */}
        <a href="https://discord.gg/lizardlabs" target='_blank' className={cn(iconClass, iconClassName)}><Icons.discord className='w-6 h-auto' /></a>
        <a href="https://x.com/intent/user?screen_name=lizlabsio" target='_blank' className={cn(iconClass, iconClassName)}><Icons.twitter className='w-6 h-auto' /></a>
        <a href="https://www.youtube.com/@lizlabsio" target='_blank' className={cn(iconClass, iconClassName)}><Icons.youtube className='w-[23px] h-auto' /></a>
        <a href="https://medium.com/lizlabsio" target='_blank' className={cn(iconClass, iconClassName)}><Icons.medium className='w-5' /></a>
    </div>
)

export const FooterContent = ({ setShowFooter, openAccordion }: FooterContentProps) => {

    const defaultAccordion = openAccordion ? {
        type: 'multiple',
        defaultValue: ["Resources", "Ecosystem", "Collections"]
    } : {
        type: 'single',
        defaultValue: ''
    };

    const AccordionWrapper = ({ children, isOpen }: { children: ReactNode[], isOpen?: boolean }) => {
        if (isOpen) {
            return <Accordion type="multiple" defaultValue={["Resources", "Ecosystem", "Collections"]} className="w-full mt-5 !text-xs flex flex-col gap-y-5">
                {children}
            </Accordion>
        } else {
            return <Accordion type="single" className="w-full mt-5 !text-xs flex flex-col gap-y-5">
                {children}
            </Accordion>
        }
    }

    return <div className='relative flex justify-center w-full h-full py-10 lg:py-8 '>

        {setShowFooter ? (
            <>
                <motion.div
                    initial={{ width: 0 }}
                    animate={{ width: "100%" }}
                    exit={{ width: 0 }}
                    transition={{ duration: 0.7, delay: 0.5 }}
                    className="absolute top-0 bg-purple-green h-[1px]"
                />
                <Button onClick={() => setShowFooter(false)} className='absolute md:hidden top-3 right-3 w-8 bg-transparent text-cyan'>
                    <X className='w-8' />
                </Button>
            </>
        ) : (
            <motion.div
                initial={{ width: 0 }}
                whileInView={{ width: "100%" }}
                exit={{ width: 0 }}
                transition={{ duration: 0.7 }}
                className="absolute top-0 bg-purple-green h-[1px]"
            />
        )}

        <BrowserView className='flex flex-col gap-y-6 md:gap-y-8 mx-auto bg-black rounded-none min-h-48 w-full max-w-6xl px-6'>
            <div className='flex flex-col items-center justify-center md:flex-row md:justify-between w-full'>
                <div><img src={logo} className='w-40 h-auto' /></div>
                <SocialIcons iconClassName="bg-[#131313] border-[#2F2F2F] hover:bg-[#131313]/90" />
            </div>
            <div className='flex flex-col md:flex-row justify-start items-center md:gap-x-16'>
                <div className='flex justify-around w-full'>
                    <div className='flex flex-col gap-y-1.5'>
                        <span className="text-white font-nekstsemibold text-base">Resources</span>
                        <a href="https://docs.google.com/spreadsheets/d/e/2PACX-1vRk2b1QuCokehqbWVwi09oBmfNZn5wyAvdrqgrz5mShe_8xv32wBCqsIbAYlMoqsvKW3ySRtsub9j__/pubhtml?gid=1964604992&single=true&widget=false&chrome=false" target="_blank" className='text-cyan font-nekstbold underline'>DAO Investment Dashboard</a>
                        <a href="https://staking.ethlizards.io/" target='_blank' className='text-cyan font-nekstbold underline'>Ethlizards Staking</a>
                        <a href="https://docs.google.com/presentation/d/e/2PACX-1vQv-t4hcWs1MMqjEWi5xCCGIP8twnOCvLn56bLQd5swSitFaZiv_YAKjdItUi5zwMpRMDZqg7pptFCF/pub?start=false&loop=false&delayms=3000" target='_blank' className='text-cyan font-nekstbold underline'>Litepaper</a>
                    </div>
                    <div className='flex flex-col gap-y-1.5'>
                        <span className="text-white font-nekstsemibold text-base">Lizard Ecosystem</span>
                        <a href="https://lizlounge.io/" target="_blank" className='text-cyan font-nekstbold underline'>Lizard Lounge App</a>
                        <a href="https://issuu.com/lizlabsio" target="_blank" className='text-cyan font-nekstbold underline'>Lizard Lookout Report</a>
                        <a href="https://open.spotify.com/show/4AZu2lB1kZ6Ath4oVkKJ2m?si=61abf425507040ad" target="_blank" className='text-cyan font-nekstbold underline'>Lizard Lookout Podcast</a>
                    </div>
                    <div className='flex flex-col gap-y-1.5'>
                        <span className="text-white font-nekstsemibold text-base">NFT Collections</span>
                        <a href="https://blur.io/eth/collection/ethlizards-genesis" target="_blank" className='text-cyan font-nekstbold underline'>Ethlizards Genesis</a>
                        <a href="https://blur.io/collection/ethlizards" target="_blank" className='text-cyan font-nekstbold underline'>Ethlizards Venture</a>
                        <a href="https://blur.io/eth/collection/larp-suits" target="_blank" className='text-cyan font-nekstbold underline'>Ethlizards LARP Suit</a>
                    </div>
                </div>
                {/* <div className='hidden md:flex h-24 w-[1px] bg-[#5E78D8] opacity-20'/>
                    <div className='flex py-6 md:py-0 gap-x-4 min-h-24'>
                        <img src={coinFace} className='h-16 w-16' style={{ filter: "drop-shadow(rgb(255, 229, 93) 0px -2px 22px) drop-shadow(rgba(0, 0, 0, 0.14) 0px -5px 0px)"}}/>
                        <div className='flex flex-col justify-between'>
                            <span className='text-[#5E78D8] font-nekstsemibold'>LIZ price</span>
                            <span className='text-white font-nekstsemibold text-2xl'>$1000.01</span>
                            
                            <div className='flex items-center'>
                                <img src={upArrow} className='h-3 w-3 mr-1' />
                                <span className='text-[#5EDB4A] font-nekstsemibold'>1248%</span>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col justify-between gap-y-2'>
                        <span className='text-[#5E78D8] font-nekstsemibold'>Available on</span>
                        <div className='flex flex-col gap-y-2'>
                            <div className='flex gap-x-6'>
                                <a className='flex items-center gap-x-2'>
                                    <img src={sushi} className='h-6 w-6 mr-1' />
                                    <span className='text-white text-xl font-nekstsemibold'>Sushi</span>
                                </a>
                                <a className='flex items-center gap-x-2'>
                                    <img src={bitcoin} className='h-6 w-6 mr-1' />
                                    <span className='text-white text-xl font-nekstsemibold'>Bitcoin.com</span>
                                </a>
                            </div>
                            <div className='flex gap-x-6'>
                                <a className='flex items-center gap-x-2'>
                                    <img src={oneinch} className='h-6 w-6 mr-1' />
                                    <span className='text-white text-xl font-nekstsemibold'>1inch</span>
                                </a>
                                <a className='flex items-center gap-x-2'>
                                    <img src={gate} className='h-6 w-6 mr-1' />
                                    <span className='text-white text-xl font-nekstsemibold'>Gate.io</span>
                                </a>
                            </div>
                        </div>
                    </div> */}
            </div>
            <div className='text-center'>
                <span className='font-figtree text-sm text-white/50'>
                    Lizard Labs © 2024, All rights reserved
                    {` `}|{` `}
                    <a href="https://ethlizards.gitbook.io/games/battle-in-the-beyond/terms-and-conditions" target='_blank' className='text-cyan'>Terms and Conditions</a>
                    {` `}|{` `}
                    <a href="https://ethlizards.gitbook.io/games/battle-in-the-beyond/privacy-policy" target='_blank' className='text-cyan'>Privacy Policy</a>
                </span>
            </div>
        </BrowserView>
        <MobileView className='flex flex-col gap-y-4 md:gap-y-12 mx-auto bg-black rounded-none min-h-48 w-full max-w-6xl px-6'>
            <div><img src={logo} className='w-40 h-auto' /></div>
            <AccordionWrapper isOpen={openAccordion}>
                <AccordionItem value="Resources">
                    <AccordionTrigger className='text-white h-8 pb-2' hideChevron={openAccordion}>
                        <span className="text-white font-nekstsemibold text-base">Resources</span>
                    </AccordionTrigger>
                    <AccordionContent>
                        <div className='flex flex-col gap-y-3'>
                            <a href="https://docs.google.com/spreadsheets/d/e/2PACX-1vRk2b1QuCokehqbWVwi09oBmfNZn5wyAvdrqgrz5mShe_8xv32wBCqsIbAYlMoqsvKW3ySRtsub9j__/pubhtml?gid=1964604992&single=true&widget=false&chrome=false" target="_blank" className='text-cyan font-nekstbold underline'>DAO Investment Dashboard</a>
                            <a href="https://staking.ethlizards.io/" target='_blank' className='text-cyan font-nekstbold underline'>Ethlizards Staking</a>
                            <a href="https://docs.google.com/presentation/d/e/2PACX-1vQv-t4hcWs1MMqjEWi5xCCGIP8twnOCvLn56bLQd5swSitFaZiv_YAKjdItUi5zwMpRMDZqg7pptFCF/pub?start=false&loop=false&delayms=3000" target='_blank' className='text-cyan font-nekstbold underline'>Litepaper</a>
                        </div>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem value="Ecosystem">
                    <AccordionTrigger className='text-white h-8 pb-2' hideChevron={openAccordion}>
                        <span className="text-white font-nekstsemibold text-base">Lizard Ecosystem</span>
                    </AccordionTrigger>
                    <AccordionContent>
                        <div className='flex flex-col gap-y-3'>
                            <a href="https://lizlounge.io/" target="_blank" className='text-cyan font-nekstbold underline'>Lizard Lounge App</a>
                            <a href="https://issuu.com/ncmpub" target="_blank" className='text-cyan font-nekstbold underline'>Lizard Lookout Report</a>
                            <a href="https://open.spotify.com/show/4AZu2lB1kZ6Ath4oVkKJ2m?si=61abf425507040ad" target="_blank" className='text-cyan font-nekstbold underline'>Lizard Lookout Podcast</a>
                        </div>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem value="Collections">
                    <AccordionTrigger className='text-white h-8 pb-2' hideChevron={openAccordion}>
                        <span className="text-white font-nekstsemibold text-base">NFT Collections</span>
                    </AccordionTrigger>
                    <AccordionContent>
                        <div className='flex flex-col gap-y-3'>
                            <a href="https://blur.io/eth/collection/ethlizards-genesis" target="_blank" className='text-cyan font-nekstbold underline'>Ethlizards Genesis</a>
                            <a href="https://blur.io/collection/ethlizards" target="_blank" className='text-cyan font-nekstbold underline'>Ethlizards Venture</a>
                            <a href="https://blur.io/eth/collection/larp-suits" target="_blank" className='text-cyan font-nekstbold underline'>Ethlizards LARP Suit</a>
                        </div>
                    </AccordionContent>
                </AccordionItem>
            </AccordionWrapper>
            <div className='flex flex-col items-center'>
                <div className='flex gap-4 py-8'>
                    <SocialIcons />
                </div>
                <div className='flex flex-col gap-y-3 items-center text-center'>
                    <span className='font-figtree text-sm text-white'>Lizard Labs © 2024, All rights reserved</span>
                    <div className='h-[1px] w-10 bg-white' />
                    <a href="https://ethlizards.gitbook.io/games/battle-in-the-beyond/terms-and-conditions" target='_blank' className='text-cyan'>Terms and Conditions</a>
                    <div className='h-[1px] w-10 bg-white' />
                    <a href="https://ethlizards.gitbook.io/games/battle-in-the-beyond/privacy-policy" target='_blank' className='text-cyan'>Privacy Policy</a>
                </div>
            </div>
        </MobileView>

    </div>
}

export const InvestorFooterContent = ({ setShowFooter, openAccordion }: FooterContentProps) => {

    const defaultAccordion = openAccordion ? {
        type: 'multiple',
        defaultValue: ["Resources", "Ecosystem", "Collections"]
    } : {
        type: 'single',
        defaultValue: ''
    };

    const AccordionWrapper = ({ children, isOpen }: { children: ReactNode[], isOpen?: boolean }) => {
        if (isOpen) {
            return <Accordion type="multiple" defaultValue={["Resources", "Ecosystem", "Collections"]} className="w-full mt-5 !text-xs flex flex-col gap-y-5">
                {children}
            </Accordion>
        } else {
            return <Accordion type="single" className="w-full mt-5 !text-xs flex flex-col gap-y-5">
                {children}
            </Accordion>
        }
    }

    return <div className='relative flex justify-center w-full h-full py-10 lg:py-8 '>

        {setShowFooter ? (
            <>
                <motion.div
                    initial={{ width: 0 }}
                    animate={{ width: "100%" }}
                    exit={{ width: 0 }}
                    transition={{ duration: 0.7, delay: 0.5 }}
                    className="absolute top-0 bg-purple-green h-[1px]"
                />
                <Button onClick={() => setShowFooter(false)} className='absolute md:hidden top-3 right-3 w-8 bg-transparent text-cyan'>
                    <X className='w-8' />
                </Button>
            </>
        ) : (
            <motion.div
                initial={{ width: 0 }}
                whileInView={{ width: "100%" }}
                exit={{ width: 0 }}
                transition={{ duration: 0.7 }}
                className="absolute top-0 bg-purple-green h-[1px]"
            />
        )}

        <BrowserView className='flex flex-col gap-y-6 md:gap-y-8 mx-auto bg-black rounded-none min-h-48 w-full max-w-6xl px-6'>
            <div className='flex flex-col items-center justify-center md:flex-row md:justify-between w-full gap-y-'>
                <div className='flex items-center gap-x-4'>
                    <img src={logo} className='max-w-xs h-[26px]' />
                    <Separator className='hidden lg:block' />
                    <img src={greenRoom} className='max-w-xs h-[25px] green-room' />
                </div>
                <SocialIcons iconClassName="bg-[#131313] border-[#2F2F2F] hover:bg-[#131313]/90" />
            </div>
            <div className='flex flex-col md:flex-row justify-start items-center md:gap-x-16'>
                <div className='flex justify-start w-full lg:px-[53px] lg:gap-x-[170px]'>

                    <div className='flex flex-col gap-y-1.5'>
                        <span className="text-white font-nekstsemibold text-base">Lizard Ecosystem</span>
                        <a href="https://lizlounge.io/" target="_blank" className='text-cyan font-nekstbold underline'>Lizard Lounge App</a>
                        <a href="https://issuu.com/lizlabsio" target="_blank" className='text-cyan font-nekstbold underline'>Lizard Lookout Report</a>
                        <a href="https://open.spotify.com/show/4AZu2lB1kZ6Ath4oVkKJ2m?si=61abf425507040ad" target="_blank" className='text-cyan font-nekstbold underline'>Lizard Lookout Podcast</a>
                    </div>
                    <div className='flex flex-col gap-y-1.5'>
                        <span className="text-white font-nekstsemibold text-base">NFT Collections</span>
                        <a href="https://blur.io/eth/collection/ethlizards-genesis" target="_blank" className='text-cyan font-nekstbold underline'>Ethlizards Genesis</a>
                        <a href="https://blur.io/collection/ethlizards" target="_blank" className='text-cyan font-nekstbold underline'>Ethlizards Venture</a>
                        <a href="https://blur.io/eth/collection/larp-suits" target="_blank" className='text-cyan font-nekstbold underline'>Ethlizards LARP Suit</a>
                    </div>
                </div>
                {/* <div className='hidden md:flex h-24 w-[1px] bg-[#5E78D8] opacity-20'/>
                    <div className='flex py-6 md:py-0 gap-x-4 min-h-24'>
                        <img src={coinFace} className='h-16 w-16' style={{ filter: "drop-shadow(rgb(255, 229, 93) 0px -2px 22px) drop-shadow(rgba(0, 0, 0, 0.14) 0px -5px 0px)"}}/>
                        <div className='flex flex-col justify-between'>
                            <span className='text-[#5E78D8] font-nekstsemibold'>LIZ price</span>
                            <span className='text-white font-nekstsemibold text-2xl'>$1000.01</span>
                            
                            <div className='flex items-center'>
                                <img src={upArrow} className='h-3 w-3 mr-1' />
                                <span className='text-[#5EDB4A] font-nekstsemibold'>1248%</span>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col justify-between gap-y-2'>
                        <span className='text-[#5E78D8] font-nekstsemibold'>Available on</span>
                        <div className='flex flex-col gap-y-2'>
                            <div className='flex gap-x-6'>
                                <a className='flex items-center gap-x-2'>
                                    <img src={sushi} className='h-6 w-6 mr-1' />
                                    <span className='text-white text-xl font-nekstsemibold'>Sushi</span>
                                </a>
                                <a className='flex items-center gap-x-2'>
                                    <img src={bitcoin} className='h-6 w-6 mr-1' />
                                    <span className='text-white text-xl font-nekstsemibold'>Bitcoin.com</span>
                                </a>
                            </div>
                            <div className='flex gap-x-6'>
                                <a className='flex items-center gap-x-2'>
                                    <img src={oneinch} className='h-6 w-6 mr-1' />
                                    <span className='text-white text-xl font-nekstsemibold'>1inch</span>
                                </a>
                                <a className='flex items-center gap-x-2'>
                                    <img src={gate} className='h-6 w-6 mr-1' />
                                    <span className='text-white text-xl font-nekstsemibold'>Gate.io</span>
                                </a>
                            </div>
                        </div>
                    </div> */}
            </div>
            <div className='text-center'>
                <span className='font-figtree text-sm text-white/50'>
                    Lizard Labs © 2024, All rights reserved
                    {` `}|{` `}
                    <a href="https://ethlizards.gitbook.io/games/battle-in-the-beyond/terms-and-conditions" target='_blank' className='text-cyan'>Terms and Conditions</a>
                    {` `}|{` `}
                    <a href="https://ethlizards.gitbook.io/games/battle-in-the-beyond/privacy-policy" target='_blank' className='text-cyan'>Privacy Policy</a>
                </span>
            </div>
        </BrowserView>
        <MobileView className='flex flex-col gap-y-4 md:gap-y-12 mx-auto bg-black rounded-none min-h-48 w-full max-w-6xl px-6'>
            <div className='flex flex-col gap-y-8'>
                <img src={logo} className='max-w-[167px] h-[25px]' />
                <img src={greenRoom} className='max-w-[215px] h-[25px] green-room' />
            </div>
            <AccordionWrapper isOpen={openAccordion}>

                <AccordionItem value="Ecosystem">
                    <AccordionTrigger className='text-white h-8 pb-2' hideChevron={openAccordion}>
                        <span className="text-white font-nekstsemibold text-base">Lizard Ecosystem</span>
                    </AccordionTrigger>
                    <AccordionContent>
                        <div className='flex flex-col gap-y-3'>
                            <a href="https://lizlounge.io/" target="_blank" className='text-cyan font-nekstbold underline'>Lizard Lounge App</a>
                            <a href="https://issuu.com/ncmpub" target="_blank" className='text-cyan font-nekstbold underline'>Lizard Lookout Report</a>
                            <a href="https://open.spotify.com/show/4AZu2lB1kZ6Ath4oVkKJ2m?si=61abf425507040ad" target="_blank" className='text-cyan font-nekstbold underline'>Lizard Lookout Podcast</a>
                        </div>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem value="Collections">
                    <AccordionTrigger className='text-white h-8 pb-2' hideChevron={openAccordion}>
                        <span className="text-white font-nekstsemibold text-base">NFT Collections</span>
                    </AccordionTrigger>
                    <AccordionContent>
                        <div className='flex flex-col gap-y-3'>
                            <a href="https://blur.io/eth/collection/ethlizards-genesis" target="_blank" className='text-cyan font-nekstbold underline'>Ethlizards Genesis</a>
                            <a href="https://blur.io/collection/ethlizards" target="_blank" className='text-cyan font-nekstbold underline'>Ethlizards Venture</a>
                            <a href="https://blur.io/eth/collection/larp-suits" target="_blank" className='text-cyan font-nekstbold underline'>Ethlizards LARP Suit</a>
                        </div>
                    </AccordionContent>
                </AccordionItem>
            </AccordionWrapper>
            <div className='flex flex-col items-center'>
                <div className='flex flex-col gap-y-3 items-center text-center'>
                    <span className='font-figtree text-sm text-white'>Lizard Labs © 2024, All rights reserved</span>
                    <div className='h-[1px] w-10 bg-white' />
                    <a href="https://ethlizards.gitbook.io/games/battle-in-the-beyond/terms-and-conditions" target='_blank' className='text-cyan'>Terms and Conditions</a>
                    <div className='h-[1px] w-10 bg-white' />
                    <a href="https://ethlizards.gitbook.io/games/battle-in-the-beyond/privacy-policy" target='_blank' className='text-cyan'>Privacy Policy</a>
                </div>
                <div className='flex gap-4 py-8'>
                    <SocialIcons />
                </div>
            </div>
        </MobileView>

    </div>
}

const Footer = () => {

    const { showFooter, setShowFooter, scrollTo } = useSections();

    WheelReact.config({
        down: () => {
            setShowFooter(false);
        }
    });

    return (
        <AnimatePresence mode="wait">
            {showFooter && (
                <>
                    <motion.div
                        key="footer_bg"
                        id="footer_bg"
                        className='absolute inset-0 bg-black/80 z-[99]'
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={() => setShowFooter(false)}
                        {...WheelReact.events}
                    />
                    <motion.footer
                        key="footer"
                        id="footer"
                        className='absolute bottom-0 z-[100] w-full bg-black max-h-[95%]'
                        initial={{ y: "100%", opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: "100%", opacity: 0 }}
                        {...WheelReact.events}
                    >
                        <FooterContent setShowFooter={setShowFooter} />
                    </motion.footer>
                </>
            )}
        </AnimatePresence>
    );
};

export default Footer;