import { cn } from "@/lib/utils";

export default function HeaderText({ className, text, tag = "h1" }: { className?: string, text: string | React.ReactNode, tag?: string }) {
    return tag === "h2" ? (
        <h2 className={cn('text-4xl lg:text-5xl lg:leading-[52px] font-bold font-khand text-investor-green', className)}>
            {text}
        </h2>
    ) : (
        <h1 className={cn('text-3xl lg:text-7xl lg:leading-[80px] font-bold font-khand text-investor-green', className)}>
            {text}
        </h1>
    )
}