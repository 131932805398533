import { motion, useInView } from "framer-motion";
import HeaderText from "./header-text";
import tentacle from "@/assets/images/invest/1.webp";
import rocks from '@/assets/images/invest/3.webp';
import { useRef } from "react";
import BlueLight from "./blue-light";

const questions = [
    {
        question: "What is a decentralized AI game publisher?",
        answer: "Decentralized Publishing is a new model that merges the benefits of decentralization, AI-development and GTM tools and The 4 Ds of publishing: Discovery, Distribution, Data and Diversity of Revenue."
    },
    {
        question: "How does it make money?",
        answer: "Lizard Labs revenue is generated through traditional publishing fees then adds revenue from AI tool licensing, and the sale of curated Novel Play Data (NPD). We share NPD revenue with developers, creating incentive for game creators and content investors beyond publishing advantages. Lizard Labs de-risks game development with revenue innovation that significantly outpaces traditional publishing alone."
    },
    {
        question: "What's the opportunity for investors?",
        answer: `The first-mover advantage is active now. Novel Play Data is a new and necessary digital commodity for the emergent AI Industry, unlocking the next generation of gaming monetization. Lizard Labs investors are poised at the forefront of decentralization, AI data licensing and publishing innovation with multiple revenue streams.`
    }
]

export default function TLDR() {
    const tentacleRef = useRef<HTMLImageElement>(null);

    const isInView = useInView(tentacleRef, { once: true });

    return (
        <div className="relative flex flex-col w-full mb-20 lg:mb-0">
            <div className="absolute top-0 left-0 w-full z-0 bg-investor-blend h-0 lg:h-[300px]" />
            <div className="absolute lg:-top-20 left-0 w-full z-0 bg-investor-blend h-0 lg:h-[200px] rotate-180" />

            <img
                src={rocks}
                alt="asteroids"
                className="absolute top-[230px] left-0 h-0 lg:h-[700px]"
            />
            <div className='absolute top-0 -right-10 lg:right-0 z-0 w-[40%] h-auto hidden lg:block'>
                <div className="relative h-full w-full">
                    <BlueLight className='absolute lg:w-[296px] lg:h-[296px] -top-[20px] z-0 rounded-full blur-[22.75px]' />
                    <motion.img
                        ref={tentacleRef}
                        src={tentacle}
                        animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 100 }}
                        transition={{ duration: 1, ease: "easeOut" }}
                        className="relative z-[1]"
                    />
                </div>
            </div>
            <div className="absolute top-[340px] -right-14 h-[380px] w-[260px] lg:h-0 lg:w-0 opacity-50 bg-black z-[-1]">
                <div
                    style={{ backgroundImage: `url(${tentacle})` }}
                    className="bg-contain bg-center bg-no-repeat w-full h-full"
                />
            </div>
            <div className='flex flex-col items-center lg:items-start text-center lg:text-left justify-center mt-[20px] lg:my-[283px] mx-auto px-[27px] py-7'>

                <HeaderText
                    text={<>TL;DR — <br className='inline lg:hidden' />Quick Answers</>}
                    tag='h2'
                    className='text-investor-green text-[32px] leading-[36px] lg:text-5xl lg:leading-[52px] font-nekstbold font-bold'
                />
                <div className='flex flex-col gap-y-8 mt-16 max-w-[332px] lg:max-w-[792px]'>
                    {questions.map((question, index) => (
                        <div key={`tldr-question-${index}`} className='flex flex-col gap-y-6 text-center lg:text-left'>
                            <p className='text-foreground text-[32px] leading-9 lg:text-4xl lg:leading-10 font-nekstbold font-bold'>
                                {question.question}
                            </p>
                            <p className='text-foreground text-[16px] lg:text-lg leading-5 font-figtree font-normal'>
                                {question.answer}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}